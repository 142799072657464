<template>
  <div>
    <!-- 大图部分 -->
    <div class="home_top">
      <div class="top_text">
        <h1 class="top_h">拓幻美颜SDK</h1>
        <p class="top_p">
          提供包括智能美颜、人脸美型、滤镜特效、动态贴纸、 虚拟背景等AR视频特效方案
        </p>
        <p class="top_p">
          广泛应用于直播、音视频社交、短视频、特效相机等应用场景
        </p>

        <router-link to="/demo"><span class="select_a">Demo预览</span></router-link>
        <router-link to="/call"><span>联系我们</span></router-link>
        <router-link to="/developer/platform"><span>更新日志</span></router-link>

        

      </div>
    </div>
    <!-- 产品部分 -->
    <div class="product">
      <Mytitle
        :data="{
          h: '我们的产品',
        }"
      ></Mytitle>

      <div class="pro_img">
        <!-- 第一列 -->
        <div class="leftOne">
          <div class="div_img">
            <img src="@/assets/home/index/首页-全局美颜.jpg" alt="全局美颜" />
            <span class="none">全局美颜</span>
            <div class="div_mask">
              <p>
                <span>全局美颜</span><br />
                「TiFancy 美颜SDK」集成精致的磨皮、
                美白、粉嫩，饱和等美颜功能，精准控制美颜特效和全局影像美化
              </p>
            </div>
          </div>
          <div class="div_img">
            <img src="@/assets/home/index/首页-精致美型.jpg" alt="精致美型" />
            <span class="none">精致美型</span>
            <div class="div_mask">
              <p>
                <span>精致美型</span><br />
                「TiFancy 美颜SDK」实时美型可以自由放大眼睛，收缩脸颊，
                微调整额头、下巴、嘴形、瘦鼻等不失真，又能够完美调整脸型，搞定各个场合的自拍和Vlog
              </p>
            </div>
          </div>
        </div>
        <!-- 第二列 -->
        <div class="leftTwo">
          <div class="div_img">
            <img src="@/assets/home/index/首页--精美面具.jpg" alt="精致美型" />
            <span class="none">精美面具</span>
            <div class="div_mask">
              <p>
                <span>精美面具</span><br />
                「TiFancy
                美颜SDK」基于高效的3D图形渲染技术，动态实现精致的3D面具特效，完美贴合人脸。
              </p>
            </div>
          </div>
          <div class="div_img">
            <img
              src="@/assets/home/index/首页--动态贴纸gif.gif"
              alt="精致美型"
            />
            <span class="none">动态贴纸</span>
            <div class="div_mask">
              <p>
                <span>动态贴纸</span><br />
                「TiFancy
                美颜SDK」基于高效的人脸识别和动态渲染技术，实现人脸动态贴纸特效。提供二百款贴纸，友好支持个性化定制。
              </p>
            </div>
          </div>
          <div class="div_img">
            <img
              src="@/assets/home/index/首页--动态礼物gif.gif"
              alt="精致美型"
            />
            <span class="none">动态礼物</span>
            <div class="div_mask">
              <p>
                <span>动态礼物</span><br />
                「TiFancy
                美颜SDK」集合多种有趣丰富的送礼物特效，充分满足您的直播送礼物的应用需求。礼物支持进行用户自定义，丰富您的直播、短视频应用。
              </p>
            </div>
          </div>
        </div>
        <!-- 第三列 -->
        <div class="rigthOne">
          <div class="div_img">
            <img src="@/assets/home/index/首页--美妆特效.png" alt="精致美型" />
            <span class="none">美妆特效</span>
            <div class="div_mask">
              <p>
                <span>美妆特效</span><br />
                「TiFancy
                美颜SDK」依托于人脸跟踪技术，实现实时美妆，瞬间拥有靓丽妆容
              </p>
            </div>
          </div>
          <div class="div_img">
            <img src="@/assets/home/index/首页--哈哈镜.png" alt="精致美型" />
            <span class="none">哈哈镜</span>
            <div class="div_mask">
              <p>
                <span>哈哈镜</span><br />
                「TiFancy
                美颜SDK」集成多款哈哈镜特效，各类恶搞效果，秒变如花妆，蛇精脸，或者冻成冰块方脸，增添相机和视频乐趣。
              </p>
            </div>
          </div>
          <div class="div_img">
            <img src="@/assets/home/index/首页--滤镜特效.png" alt="精致美型" />
            <span class="none">滤镜特效</span>
            <div class="div_mask">
              <p>
                <span>滤镜特效</span><br />
                「TiFancy
                美颜SDK」拥有近100套精美的滤镜可供选择，友好支持个性化定制，可实时切换和效果预览
              </p>
            </div>
          </div>
        </div>
        <!-- 第四列 -->
        <div class="rigthTwo">
          <div class="div_img">
            <img src="@/assets/home/index/首页--抖动特效.png" alt="精致美型" />
            <span class="none">抖动特效</span>
            <div class="div_mask">
              <p>
                <span>抖动特效</span><br />
                「TiFancy
                美颜SDK」集合多种有趣稳定的抖音特效，充分满足您的抖音应用需求和个性化定制，丰富您的直播、短视频应用。
              </p>
            </div>
          </div>
          <div class="div_img">
            <img src="@/assets/home/index/首页--长腿瘦身.png" alt="精致美型" />
            <span class="none">长腿瘦身</span>
            <div class="div_mask">
              <p>
                <span>水印LOGO</span><br />
                「TiFancy
                美颜SDK」为特效相机、直播、短视频提供实时视频和图片添加水印功能，水印图片、位置以及添加方式可自定义。
              </p>
            </div>
          </div>
          <div class="div_img">
            <img src="@/assets/home/index/首页--水印LOGO.png" alt="精致美型" />
            <span class="none">水印LOGO</span>
            <div class="div_mask">
              <p>
                <span>人像抠图</span><br />
                「TiFancy
                美颜SDK」基于AI人像背景分割技术，实时进行直接的背景替换或背景虚化处理，无需依赖绿幕，配合各类趣味脑洞背景素材，提升娱乐体验。
              </p>
            </div>
          </div>
          <router-link to="/product?id=0"
            ><div class="more"><span>了解更多</span></div></router-link
          >
        </div>
      </div>
    </div>
    <!-- 优势部分 -->
    <div class="advantage">
      <Mytitle
        :data="{
          h: '我们的优势',
          p: '以用户需求为导向，不断优化产品，带给用户极致体验感',
        }"
      ></Mytitle>

      <div class="adv_conter">
        <div class="friendly">
          <img src="@/assets/home/index/icon_advantage_youhao@2x.png" />
          <h3>集成友好</h3>
          <p>SDK的集成方式简便，接口友好易接入，让您轻松使用</p>
        </div>
        <div class="efficient">
          <img src="@/assets/home/index/icon_advantage_gaoxiao@2x.png" />
          <h3>稳定高效</h3>
          <p>
            具备广泛的用户基础，提供毫秒级别的接口调用速度，有效解决应用过程中阻塞卡顿的问题
          </p>
        </div>
        <div class="edit">
          <img src="@/assets/home/index/icon_advantage_bianji@2x.png" />
          <h3>自定义编辑</h3>
          <p>
            依托高效便捷的人脸道具编辑工具，可以定制丰富有趣的2D、3D贴纸道具
          </p>
        </div>
        <div class="compatible">
          <img src="@/assets/home/index/icon_advantage_jianrong@2x.png" />
          <h3>良好兼容</h3>
          <p>多平台易使用。目前友好支持腾讯云、七牛云等主流云平台推流应用</p>
        </div>
      </div>
    </div>
    <!-- 场景应用部分 -->
    <div class="scene">
      <!-- :style="{
        backgroundImage: 'url(' + sceneShow.bgImg + ')',
      }" -->
      <img
        class="img"
        :class="sceIndex == 0 ? 'show' : ''"
        src="@/assets/home/index/应用场景_直播场景首图.png"
        alt=""
      />
      <img
        class="img"
        :class="sceIndex == 1 ? 'show' : ''"
        src="@/assets/home/index/应用场景_视频场景首图.png"
        alt=""
      />
      <img
        class="img"
        :class="sceIndex == 2 ? 'show' : ''"
        src="@/assets/home/index/应用场景_智慧屏场景首图.png"
        alt=""
      />
      <div class="sce_title">
        <h1>多场景应用</h1>
        <span class="line"></span>
        <p>
          TiFancy美颜SDK满足直播、视频、智慧屏等多样化应用场景的美颜特效需求
        </p>
      </div>
      <div class="sce_conter">
        <ul class="list">
          <li
            v-for="(item, index) in sceneList"
            :key="index"
            @click="showItem(item, index)"
            :class="index == sceIndex ? 'sceIndex' : ''"
            @mousemove="showItem(item, index)"
          >
            <img class="icon" :src="item.icon" />
            {{ item.title }}
          </li>
        </ul>
        <div class="text">
          <h2>{{ sceneShow.smallTit }}</h2>
          <p>
            {{ sceneShow.text }}
          </p>
          <a @click="toScene(sceneShow.id)"> 了解详情</a>
        </div>
      </div>
    </div>
    <!-- 支持云平台 -->
    <div class="cloud">
      <Mytitle
        :data="{
          h: '支持云平台',
          p: 'TiFancy美颜SDK/萌颜SDK可支持所有主流云平台',
        }"
      ></Mytitle>
      <div class="clo_img">
        <img src="@/assets/home/index/img_hezuo_1@2x.png" />
        <img src="@/assets/home/index/img_hezuo_2@2x.png" />
        <img src="@/assets/home/index/img_hezuo_3@2x.png" />
        <img src="@/assets/home/index/img_hezuo_4@2x.png" />
        <img src="@/assets/home/index/img_hezuo_5@2x.png" />
        <img src="@/assets/home/index/img_hezuo_6@2x.png" />
        <img src="@/assets/home/index/img_hezuo_7@2x.png" />
        <img src="@/assets/home/index/img_hezuo_8@2x.png" />
        <img src="@/assets/home/index/img_hezuo_9@2x.png" />
        <img src="@/assets/home/index/img_hezuo_10@2x.png" />
        <img src="@/assets/home/index/img_hezuo_11@2x.png" />
        <img src="@/assets/home/index/img_hezuo_12@2x.png" />
      </div>
      <vue-lazy-component tagName="div">
        <div class="clo_num" ref="Box">
          <div v-for="(num, i) in cloudList" :key="i">
            <h2>
              <countTo
                :startVal="num.startVal"
                :endVal="num.endVal"
                :duration="num.duration"
                slot="skeleton"
              ></countTo
              >{{ num.unit }}
            </h2>

            <p>{{ num.text }}</p>
          </div>
        </div>
      </vue-lazy-component>
    </div>
    <!-- 新闻动态 -->
    <div class="news">
      <Mytitle
        :data="{
          h: '新闻动态',
        }"
      ></Mytitle>
      <div class="news_conter">
        <div class="con_img">
          <div class="con_div">
            <div class="image">
              <img src="@/assets/home/index/首页--拓幻资讯.png" alt="" />
            </div>
            <span class="text_span"
              >拓幻资讯
              <ul class="text_ul">
                <li>
                  <a
                    target="_blank"
                    href="https://mp.weixin.qq.com/s?__biz=MzU5NDMzNDI0Ng==&amp;mid=2247487360&amp;idx=2&amp;sn=26e2105cc7810832a67006549430f743&amp;chksm=fe0388c9c97401df5426a3f9fde47c9532d359f34b611be620fdb50d915b73833c93715892e7&amp;token=240555228&amp;lang=zh_CN"
                    >新功能即将来袭，终于可以用了！ <span>2021-03-30</span></a
                  >
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://mp.weixin.qq.com/s?__biz=MzU5NDMzNDI0Ng==&mid=2247487320&idx=1&sn=0e8d79e08c07e6dde774b5294001d48d&chksm=fe038811c97401074c8eeb2db2e1e45425325c434532e29e71ecd6cade82b9cbf8b9351c3a38&token=240555228&lang=zh_CN#rd"
                    >云粉们看过来！这里有你们喜欢... <span>2021-03-28</span></a
                  >
                </li>
              </ul>
            </span>
          </div>
          <div class="con_div">
            <div class="image">
              <img src="@/assets/home/index/首页--行业信息.png" alt="" />
            </div>
            <span class="text_span"
              >行业信息
              <ul class="text_ul">
                <li>
                  <a
                    target="_blank"
                    href="https://www.chinairn.com/news/20150712/104455436.shtml"
                    >美颜经济兴起,美颜类应用有多少... <span>2021-03-30</span></a
                  >
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.chyxx.com/industry/201912/818377.html"
                    >2019年上半年中国拍摄美化行业... <span>2021-03-28</span></a
                  >
                </li>
              </ul>
            </span>
          </div>
          <div class="con_div">
            <div class="image">
              <img src="@/assets/home/index/首页--产品更新.png" alt="" />
            </div>
            <span class="text_span"
              >产品更新
              <ul class="text_ul">
                <li>
                  <router-link to="/developer/platform">
                    <a href="#"
                      >Tifancy带来全新功能与体验 <span>2021-05-05</span></a
                    ></router-link
                  >
                </li>
                <li>
                  <router-link to="/developer/platform">
                    <a href="#"
                      >Tifancy让升级不止于此！ <span>2021-04-15</span></a
                    ></router-link
                  >
                </li>
              </ul>
            </span>
          </div>
        </div>
        <div class="con_text">
          <div class="text">
            <h3>
              体验我们的Demo
              <img src="@/assets/home/index/icon_demo@2x.png" alt="" />
            </h3>
            <p class="p_one">
              美颜SDK/萌颜SDK Demo -
              TiFancy（iOS版本，Android版本）集成了拓幻科技的部分核心技术，
              囊括了全局美颜、精致美型、微整形、动态贴纸、滤镜特效，美妆特效、抖动特效、互动贴纸、水印logo、精致面具、哈哈镜、一键美颜、脸型精修、绿幕特效、AI人像抠图、长腿瘦身等特效，提供了视频拍照、图片编辑等场景下的体验。
            </p>
            <p class="p_two">
              您可以通过扫描识别右侧二维码，免费下载，轻松体验TiFancy美颜SDK带来的精致化娱乐方式！
            </p>
          </div>
          <img src="@/assets/home/index/demo@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to"; //数字滚动插件
import Mytitle from "@/components/Mytitle/index"; //标题
import { component as VueLazyComponent } from "@xunlei/vue-lazy-component"; //组件懒加载

export default {
  components: {
    countTo,
    Mytitle,
    "vue-lazy-component": VueLazyComponent,
  },
  data() {
    return {
      // 多场景应用数据
      sceneList: [
        {
          id: 0,
          title: "直播应用场景",
          smallTit: "直播应用场景",
          icon: require("@/assets/home/index/icon_scene_live@2x.png"),
          bgImg: require("@/assets/home/index/应用场景_直播场景首图.png"),
          text: "适用于娱乐直播、电商直播（直播带货）、教育直播、传媒直播等多类别直播场景的互动玩法",
        },
        {
          id: 1,
          title: "视频应用场景",
          smallTit: "短视频",
          icon: require("@/assets/home/index/icon_scene_video@2x.png"),
          bgImg: require("@/assets/home/index/应用场景_视频场景首图.png"),
          text: "适用于短视频、视频通话、视频会议、视频编辑等多样化场景的互动玩法",
        },
        {
          id: 2,
          title: "智慧屏应用场景",
          smallTit: "智慧屏",
          icon: require("@/assets/home/index/icon_scene_zhihuiping@2x.png"),
          bgImg: require("@/assets/home/index/应用场景_智慧屏场景首图.png"),
          text: "适用于线下大屏、广告屏、美妆镜等不同屏幕场景的互动玩法",
        },
      ],
      //多场景显示数据
      sceneShow: {
        id: 0,
        title: "直播应用场景",
        smallTit: "直播应用场景",
        icon: require("@/assets/home/index/icon_scene_live@2x.png"),
        bgImg: require("@/assets/home/index/应用场景_直播场景首图.png"),
        text: "适用于娱乐直播、电商直播（直播带货）、教育直播、传媒直播等多类别直播场景的互动玩法",
      },
      sceIndex: 0, //多场景默认选中
      newsShow: false, //新闻动态悬停监听
      newsShow1: false,
      // 云平台数据
      cloudList: [
        {
          startVal: 0,
          endVal: 1,
          duration: 3000,
          unit: "亿+",
          text: "累计全球用户体验",
        },
        {
          startVal: 0,
          endVal: 1000,
          duration: 3000,
          unit: "万",
          text: "累计全球装机量",
        },
        {
          startVal: 0,
          endVal: 100,
          duration: 3000,
          unit: "家",
          text: "服务合作商",
        },
        {
          startVal: 0,
          endVal: 30,
          duration: 3000,
          unit: "%",
          text: "全国市场占有率",
        },
        {
          startVal: 0,
          endVal: 50,
          duration: 3000,
          unit: "人",
          text: "技术团队支持",
        },
      ],
    };
  },
  methods: {
    // 多场景悬停切换
    showItem(item, index) {
      let img = new Image();
      this.sceneShow = item;
      this.sceIndex = index;
      img.onload = function () {
        e.target.style.backgroundImage = "url(" + item.bgImg + ")";
      };
    },
    //多场景跳转
    toScene(index) {
      this.$router.push({ path: "/scene", query: { id: index } });
    },
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
//背景大图部分
.home_top {
  width: 100%;
  height: 500px;
  line-height: 425px;
  text-align: left;
  color: #fff;
  background: url("~@/assets/home/index/首页首图--bg.png") no-repeat center;
  background-size: 100% 500px;
  .top_text {
    width: 1440px;
    height: 100%;
    margin: auto;
    background: url("~@/assets/home/index/首页首图.png") no-repeat center;
    background-size: 100%;
    .top_h {
      margin-left: 60px;
      height: 47px;
      font-size: 50px;
      font-weight: 500;
      margin-bottom: 40px;
    }
    .top_p {
      margin-left: 60px;
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 25px;
      margin-top: 10px;
    }
    span {
      margin-left: 60px;
      margin-top: 220px;
      text-align: center;
      color: #fff;
      display: inline-block;
      width: 160px;
      height: 40px;
      margin-right: 8px;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #ffffff;
      border-radius: 4px;
      line-height: 40px;
      cursor: pointer;
    }
    span:hover {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff;
      border-radius: 4px;
    }
    .select_a {
      background: #4C8CF5;
      border: 0;
    }
    .select_a:hover {
      background: #2673f1;
      border: 0;
    }
  }
}

// 产品部分
.product {
  width: 100%;
  height: auto;
  margin: auto;
  .pro_img {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    .leftOne,
    .leftTwo,
    .rigthOne,
    .rigthTwo {
      width: 25%;
      .div_img {
        overflow: hidden;
        position: relative;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        span.none {
          position: absolute;
          top: 50%;
          /* 偏移50% */
          transform: translateX(-50%) translateY(-50%);
        }
        img {
          position: relative;
          display: block;
          width: 100%;
          transition: all 1s;
          z-index: -1;
        }
        .div_mask {
          width: 100%;
          height: 100%;
          background: rgba($color: #5367e2, $alpha: 0.5);
          position: absolute;
          top: 100%;
          transition: top 1s;

          p {
            width: 242px;
            margin: auto;
            line-height: 24px;
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            top: 50%;
            left: 50%;
            /* 偏移50% */
            transform: translateX(-50%) translateY(-50%);
            span {
              font-size: 20px;
              font-weight: 500;
              display: inline-block;
              height: 51px;
            }
          }
        }
      }
      .div_img:hover img {
        transform: scale(1.2);
      }
      .div_img:hover .none {
        opacity: 0;
      }
      .div_img:hover .div_mask,
      .div_img:hover .small_mask {
        top: 0px;
      }
      .more {
        color: #666666;
        font-size: 20px;
        font-weight: 400;
        padding: 46% 0;
        span {
          display: inline-block;
          width: 106px;
          margin: auto;
          text-align: left;
          background: url("~@/assets/home/index/icon_product_more@2x.png")
            no-repeat 100%;
          background-size: 18px;
        }
      }
      .more:hover span {
        color: #4c8cf5;
        background: url("~@/assets/home/index/icon_product_more_hover@2x.png")
          no-repeat 100%;
        background-size: 18px;
      }
    }
  }
}
// 优势部分
.advantage {
  .adv_conter {
    width: $width;
    margin: auto;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    text-align: center;
    height: 266px;
    img {
      width: 60px;
    }
    h3 {
      height: 52px;
      font-size: 16px;
      color: #222222;
      line-height: 52px;
    }
    p {
      width: 220px;
      word-break: break-all;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
    }
  }
}
// 多场景应用
.scene {
  height: 600px;
  color: #ffffff;
  padding-top: 1px;
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px; */
  position: relative;
  @keyframes fadeInOut {
    0% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInOut {
    0% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  .img {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    animation: fadeInOut 1s linear;
    -webkit-animation: fadeInOut 1s linear;
  }
  .show {
    display: block;
  }
  .sce_title {
    margin: 79px auto;
    text-align: center;
    h1 {
      height: 38px;
      font-size: 30px;
      font-weight: 500;
    }
    .line {
      display: block;
      width: 60px;
      height: 4px;
      background: #fff;
      margin: 9px auto;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 38px;
    }
  }
  .sce_conter {
    display: flex;
    justify-content: center;
    .list {
      width: 280px;
      height: 240px;
      background: rgba($color: #1d1d1d, $alpha: 0.3);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
      line-height: 26px;
      text-align: left;
      margin-right: 148px;
      .icon {
        width: 31px;
        vertical-align: middle;
        margin: 0 27px;
      }
      li {
        width: 280px;
        height: 80px;
        line-height: 80px;
        cursor: pointer;
        transition: all 1s;
      }
      .sceIndex {
        transform: scale(1.1);
        background: #4c8cf5
          url("~@/assets/home/index/icon_scene_selected@2x.png") no-repeat 243px
          center;
        background-size: 20px;
        color: #ffffff;
        font-size: 20px;
      }
    }
    .text {
      width: 600px;
      height: 240px;
      font-weight: 400;
      h2 {
        height: 50px;
        font-size: 20px;
      }
      p {
        width: 538px;
        height: 95px;
        font-size: 14px;
        color: #cccccc;
      }
      a {
        display: inline-block;
        width: 160px;
        height: 40px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
      }
      a:hover {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #ffffff;
      }
    }
  }
}
// 云平台
.cloud {
  .clo_img {
    width: $width;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    img {
      width: 19.6%;
      margin: 20px 30px;
      box-shadow: 0px 0px 18px rgba(180, 180, 180, 0.46);
      transition: all 0.5s;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0px 0px 18px rgba(144, 177, 232, 0.46);
      transform: translateY(-10px);
    }
  }
  .clo_num {
    width: $width;
    margin: auto;
    padding-top: 68px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    height: 120px;
    div {
      height: 120px;
    }
    h2 {
      height: 58px;
      font-size: 40px;
      font-weight: 500;
      color: #ff4c4c;
      line-height: 38px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 38px;
    }
  }
}
// 新闻动态
.news {
  .news_conter {
    height: 932px;
    background: url("~@/assets/home/index/bg_xinwen@2x.png") no-repeat 0 100px;
    background-size: 100% 380px;
    .con_img {
      height: 424px;
      text-align: center;
      .con_div {
        width: 360px;
        display: inline-block;
        margin-right: 30px;
        position: relative;
        cursor: pointer;
        .image {
          height: 200px;
          overflow: hidden;
        }
        img {
          width: 360px;
          height: 200px;
          transition: all 1s;
        }
        .text_span {
          text-align: left;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 330px;
          height: 70px;
          background: #ffffff;
          font-size: 20px;
          font-weight: 500;
          color: #222222;
          padding: 15px;
          position: absolute;
          top: 200px;
          transition: all 1s;
          ul {
            display: none;
          }
          li {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            margin-top: 16px;
            a {
              color: #999999;
            }
            a:hover {
              color: #4c8cf5;
            }
            span {
              float: right;
            }
          }
        }
      }
      .con_div:hover img {
        transform: scale(1.2);
      }
      .con_div:hover .text_span {
        top: 160px;
        height: 110px;
      }
      .con_div:hover .text_ul {
        display: block;
      }
    }
    .con_text {
      height: 508px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .text {
        h3 {
          height:60px;
          font-size: 30px;
          font-weight: 500;
          color: #4c8cf5;
          line-height: 38px;
        }
        img {
          width: 61px;
          height: 28px;
        }
        p {
          width: 602px;
          height: 86px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .p_two {
          color: #4c8cf5;
          margin-top: 28px;
        }
      }
      img {
        width: 190px;
        height: 190px;
      }
    }
  }
}
</style>